<template>
  <div class="contain">
    <div class="modalityCenter">
             <el-button
          size="mini"
          type="text"
          class="addButton"
          v-if="$anthButtons.getButtonAuth('addcar')"
          @click="addItem(1)"
          >添加</el-button>
    <el-table
     border
      :data="tableData"
      style="width: 100%"
      class="table-no-search"
      height="calc(100vh - 256px)"
      :row-class-name="tableRowClassName"
      :header-cell-style="{
        color: '#333',
        fontFamily: 'MicrosoftYaHeiUI',
        fontSize: '14px',
        fontWeight: 900,
        textAlign:'center',
        background: '#f8f8f9',
      }"
    >
    <el-table-column
      type="index"
      width="80px"
      align="left"
      label="序号">
    </el-table-column>
    <el-table-column align="left" prop="carNum" label="车牌号"></el-table-column>
      <el-table-column align="left" prop="driverName" label="司机姓名" show-overflow-tooltip></el-table-column>
      <el-table-column align="left"  prop="driverPhone" label="联系电话"></el-table-column>
       <el-table-column align="center" prop="acquisitionDate" label="购置日期"></el-table-column>
       <el-table-column align="left" prop="gpsImei" label="物联设备编码"></el-table-column>
      <el-table-column align="left" width="170" prop="updateTime" label="更新时间"></el-table-column>
      <el-table-column label="操作" align="left" width="257px">
        <template slot-scope="{ row }">
          <el-button type="primary" size="small" 
          v-if="$anthButtons.getButtonAuth('editcar')"
          @click="addItem(row)">编辑</el-button>
          
          <el-button
            @click.native.prevent="changeStatus(3, row.id)"
            type="danger"
            v-if="$anthButtons.getButtonAuth('deletecar')" 
            size="small">删除</el-button >
          
        </template>
      </el-table-column>
    </el-table>

    <div style="text-align: left">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="search.current"
        :page-sizes="[10, 30, 50, 100]"
        :page-size="search.size"
        background
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
    </div>
   <el-dialog
        :title="title"
        :visible.sync="showDialogVisible"
        :show-close="true"
        :close-on-click-modal="false"
        width="460px"
        @close="close"
        >
         <el-form
          ref="formAdd"
          :model="form"
          :rules="rules"
          label-width="110px"
          class="formBox"
           size="small"
        >
          <el-form-item label="车牌号" prop="carNum">
            <el-input v-model="form.carNum" style="width:100%"></el-input>
          </el-form-item>
          <el-form-item label="司机姓名" >
            <el-input v-model="form.driverName" style="width:100%"></el-input>
          </el-form-item>
          <el-form-item label="联系电话">
            <el-input v-model="form.driverPhone" style="width:100%"></el-input>
          </el-form-item>
          <el-form-item label="购置日期" >
            <el-date-picker
                    size="mini"
                    v-model="form.acquisitionDate"
                    type="date"
                    style="width:100%"
                    value-format="yyyy-MM-dd"
                    placeholder="选择日期" >
                </el-date-picker>
          </el-form-item>
          <el-form-item label="物联设备编码" prop="gpsImei">
            <el-input v-model="form.gpsImei" style="width:100%"></el-input>
          </el-form-item>
         
        </el-form>
         <span slot="footer" class="dialog-footer">
          <el-button size="small" @click="showDialogVisible = false">取 消</el-button>
          <el-button size="small" type="primary" @click="onSubmit()">确 定</el-button>
        </span>
    </el-dialog>
  </div>
</template>

<script>
import {patrolCarLists,addpatrolCar,updatepatrolCar,deletepatrolCar} from "@/RequestPort/dict/cycle.js";
import {getCarInfo} from '@/views/Gis/apis/base'
export default {
  name: "inspePlan",
  data() {
    return {
      showDialogVisible:false,
      excutorDownArray: [],
      networkList:[],
      // // 任务情况
      // mission:[
      //   { label: "未安排", value: 2 },
      //   { label: "已安排", value: 1 },
      // ],
      // // 状态
      // stateList:[
      //   { label: "已开启", value: 1 },
      //   { label: "已关闭", value: 2 },
      // ],
      // monthArray: [],
      // DataPicker: [],
      search: {
        current: 1,
        size: 100,
      },
      // status: "",
      // 列表数据
      tableData: [],
      // searchText: "",
      // 总条数
      total: 0,
      // choseArr: [],
     
      form: {
        carNum:'',
        gpsImei:'',
        driverName:'',
        driverPhone:'',
        acquisitionDate: "",
      },
      // 设备
      // equipment:[],
      rules:{
        carNum: [{ required: true, message: "请填写车牌号", trigger: "blur" }],
        gpsImei: [{ required: true, message: "请填写物联设备编码", trigger: "blur" }],
      },
      title:'添加',
      // loading:null
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    onSubmit(){
      this.$refs.formAdd.validate((valid) => {
          if (!valid) return false
          if(this.title == "添加") {
            addpatrolCar(this.form).then(res=>{
              this.$message.success(res.msg);
              this.showDialogVisible = false;
              this.getList();
            })
          } else {
            updatepatrolCar(this.form).then(res=>{
              this.$message.success(res.msg);
              this.showDialogVisible = false;
              this.getList();
            })
          }
      })
    },
    addItem(row){
      if(row!=1){
        this.title = '编辑'
        getCarInfo({caraId:row.id}).then(res => {
          if(res.code == 200) {
            this.form = res.data
          }
        })
      }else{
        this.title = '添加'
      }
      this.showDialogVisible = true
      if(this.$refs.formAdd){
        this.$refs.formAdd.resetFields();
      }
    },
    close(){
      this.form = {
        carNum:'',
        gpsImei:'',
        driverName:'',
        driverPhone:'',
        acquisitionDate: "",
        networkId: "",
      }
      this.$refs.formAdd.resetFields()
    },
    /**
     * 斑马线
     */
    tableRowClassName({row, rowIndex}) {
        if (rowIndex%2==0) {
          return 'warning-row';
        } else {
          return 'success-row';
        }
      },
    /**
     * 删除
     */
    changeStatus(type, id) {
      if (id) {
        this.$confirm("是否确认删除?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          closeOnClickModal:false,
          type: "warning",
        }).then(() => {
          deletepatrolCar({ids: id}).then((res) => {
            if (res.code === 200) {
              this.$message.success(res.msg);
              this.getList();
            }
          });
        });
        return;
      }
     
    },
    /**
     * 查询列表数据
     */
    getList() {
      // if(this.tableData.length == 1 && (this.search.current != 1)) {
      //   this.search.current -= 1 
      // }
      patrolCarLists(this.search).then((res) => {
        if (res.code === 200) {
          const { records, total } = res.data;
          this.tableData = records;
          this.total = total;
        }
      });
    },
    handleSizeChange(e) {
      this.search.size = e;
      this.getList();
    },
    handleCurrentChange(e) {
      this.search.current = e;
      this.getList();
    },
  },
};
</script>

<style lang="less" scoped>
.contain{
  background-color:transparent;
  padding: 0;
}

// 搜索栏样式
.modalityTop{
    border: 1px solid #ecedf1;
    padding: 10px;
    border-radius: 5px;
    background: #ffffff;
    margin-bottom: 12px;
  // // 面包屑
  // .crumbs{
  //   padding: 10px 15px 20px;
  //   span{
  //     color: #3069E1;
  //   }
  // }
  // .el-form-item{
  //   margin-left: 20px;
  // }
  // .el-form-item__label{
  //   text-align: justify;
  // }
  // ::v-deep.el-input__inner{
  //   height: 36px;
  //   line-height: 36px;
  // }
}
// 表格样式
.modalityCenter{
  background: #ffffff;
  padding: 10px;
  // 新增按钮
  .addButton{
    margin-bottom: 10px;
    float:right;
    border: 1px solid #1082ff;
    color: #1082ff;
    padding: 0 15px;
    height: 30px;
    margin: 0 11px 10px;
  }
  // ::v-deep.warning-row{
  //   background: #F9FAFC;
  //    .el-table__cell>.cell {
  //   font-size: 14px;
  //   color: #0C235B;
    
  //    }
  // }
// ::v-deep.success-row{ 
//   background: #ffffff;
//   .el-table__cell>.cell {
//     font-size: 14px;
//     color: #0C235B;
//   }
// }
}
// ::v-deep.el-table th.el-table__cell>.cell{
//   font-size: 15px;
// }

// 搜索
.searchForm{
  display: flex;
  flex-wrap: wrap;
  //   justify-content: space-between;
  .el-form-item{
    margin: 0;
  }
}
// 区域鼠标移上事件
.examine{
 cursor: pointer;
}
// 分页按钮
.el-pagination{
      margin: 15px 0 5px;
}
.view-table {
  width: 100%;
  border-collapse: collapse;
  border: none;
}
.view-table th {
  // background-color: #f5faff;
  font-weight: 400;
  text-align: left;
  width: 180px;
}
.view-table td,
.view-table th {
  border: 1px solid #d1e8fe;
  line-height: 40px;
  padding: 0 15px;
}
.top-bar {
  display: flex;
  justify-content: space-between;
  .left {
    width: 30%;
    margin: 10px 0;
  }
  .right {
    width: 35%;
  }
}
.el-input-group,
.el-input {
  width: initial;
}
.reset {
  margin-left: 10px;
}
.block {
  text-align: right;
}
</style>
